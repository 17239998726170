<script>
var moment = require('moment');
moment.locale ('fr');
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from '../../../helpers';
import { Erreur } from "../../../helpers/error";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
  },
  
  data() {
    return {
      process:false,
      modalOk:false,
      linkInfos:{
        titre:"",
        url:""
      },
      links:[],
      updateModal:false,
      moment:moment,
      contenu:"video",
      videos:[],
      programmes:[],
      title: "Liste des liens",
      items: [
        {
          text: "Outils",
          // href: "/projects/link",
        },
        {
          text: "Liens",
          active: true,
        },
      ],
    };
  },
  methods:{
    showOneLink(){
      this.updateModal = true
    },
    showModal(){
      this.modalOk = true
    },

    createTags(){
      this.process = true
      this.modalOk = false

      Api.post(`/streamvod/rest/politique/create-politique?name=${this.linkInfos.titre}&url=${this.linkInfos.url}`)
      .then( () =>{
        this.process = false
        Swal.fire("Success!", "Tag ajoutée", "success");
        location.reload()
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
    },

      
    confirm(id) {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteProgramme(id)
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },
    deleteProgramme(id) {
      Api.delete(`/streamvod/rest/politique/delete-politique/${id}`)
        .then((response) => {
          this.channels = response.data.content
          location.reload()
        }).catch((error) => {
          Erreur.gestionErreur(error.message)
        })
    },
    
  },
  mounted(){
      
    Api.get("/streamvod/api/politique/all" )
    .then((res) => {
      this.links = res.data.content
    }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row justify-content-end">
        <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn c2play-primary" style="position: relative; left: 90%;" @click="showModal">
                {{ $t('addButton') }}
            </button>
        </div>
    </div>
    <br>
    
    
    
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
              
            >
              <thead>
                <tr>
                  <th scope="col">{{ $t('pages.link.entete.th1') }}</th>
                  <th scope="col">{{ $t('pages.link.entete.th2') }}</th>
                  <th scope="col">{{ $t('actions.title') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="link in links" :key="link.id">
                  <td>
                    {{ link.name }}
                  </td>
                  <td>
                    <a target="blanck" :href="link.url">
                        {{ link.url }}
                    </a>
                  </td>
                  <td>
                    <b-dropdown
                      class="card-drop"
                      variant="white"
                      menu-class="dropdown-menu-end"
                      right
                      toggle-class="p-0"
                    >
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>
                      <!-- <b-dropdown-item href="javascript: void(0);" @click="showOneLink"
                        >{{ $t('actions.modifier') }}</b-dropdown-item
                      > -->
                      <b-dropdown-item @click="confirm(link.id)"
                        >{{ $t('actions.supprimer') }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
                  <b-modal
                    v-model="modalOk"
                    id="modal-ml"
                    size="ml"
                    title="Modififer le lien"
                    title-class="font-18"
                    hide-footer
                  >

                  <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label"
                  >{{ $t('pages.link.formulaire.titre.label') }}</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="linkInfos.titre"
                    :placeholder="$t('pages.link.formulaire.titre.placeholder')"
                  />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label"
                  >{{ $t('pages.link.formulaire.url.label') }}</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="linkInfos.url"
                    :placeholder="$t('pages.link.formulaire.url.placeholder')"
                  />
                </div>
              </div>
              
            </form>
            <div class="row justify-content-end">
              <div class="col-lg-10" style="margin-top: 10px;">
                <button type="submit" class="btn c2play-primary" style="position: relative; left: 30%;" @click="createTags">
                  {{ $t('addButton') }}
                </button>
              </div>
            </div>


            
          </div>
                  </b-modal>


                  
                  <b-modal
                    v-model="updateModal"
                    id="modal-ml"
                    size="ml"
                    :title="$t('pages.link.formulaire.title')"
                    title-class="font-18"
                    hide-footer
                  >

                  <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label"
                  >{{ $t('pages.link.formulaire.titre.label') }}</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="linkInfos.titre"
                    :placeholder="$t('pages.link.formulaire.titre.placeholder')"
                  />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label"
                  >{{ $t('pages.link.formulaire.url.label') }}</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="linkInfos.url"
                    :placeholder="$t('pages.link.formulaire.url.placeholder')"
                  />
                </div>
              </div>
              
            </form>
            <div class="row justify-content-end">
              <div class="col-lg-10" style="margin-top: 10px;">
                <button type="submit" class="btn c2play-primary" style="position: relative; left: 30%;" @click="createTags">
                  {{ $t('updateButton') }}
                </button>
              </div>
            </div>


            
          </div>
                  </b-modal>
                  <loaderProcess v-if="process == true"></loaderProcess>
    <!-- end row -->
  </Layout>
</template>

<style>
  
</style>